<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <!-- 部门 -->
            <div class="search_center">
              <span>费用所属部门：</span>
              <DeptCascader
                :placeholder="'请选择费用所属部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>

            <!-- 姓名 -->
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入姓名"
              >
              </el-input>
            </div>
            <!-- 单据编号 -->
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
              >
              </el-input>
            </div>

            <!-- 所属单位 -->
            <div class="search_center">
              <span>所属单位：</span>
              <el-select
                v-model="parameter.companyType"
                @change="dataUpdate"
                filterable
                placeholder="请选择所属单位"
                class="ipt_width"
              >
                <el-option
                  v-for="item in companyTypeList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.dictVal"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>流程进度：</span>
              <Dictionary
                v-model="parameter.actStatus"
                class="ipt_width"
                code="PUBLIC_SPENDING_EXPENSE"
                placeholder="请选择流程进度"
              />
            </div>
            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>单据金额：</span>
              <NumberRangeInput v-model="numberRange" />
            </div>
            <div class="search_center">
              <span>科目选择：</span>
              <SubjectCascader
                v-model="subjectList"
                :checkStrictly="true"
                class="ipt_width"
                code="CBFYZC"
                placeholder="请选择科目"
              />
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button
            type="primary"
            icon="el-icon-printer"
            :loading="exportingLoading"
            v-if="permission(['ALL_DOCUMENT_EXPORT'])"
            @click="exportingFn"
            >导出</el-button
          >
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              width="160px"
              label="单据编号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createName"
              sortable
              label="姓名"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="deptName"
              sortable
              min-width="130"
              label="费用所属部门"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="companyType"
              width="130"
              sortable
              label="费用所属单位"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template>
            </el-table-column>
            <el-table-column prop="sumCost" width="110" sortable label="报销金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="110"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" width="150" prop="status" label="报销进度">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.status) }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column align="center" prop="auditDate" label="审核时间">
              <template slot-scope="scope">
                {{ scope.row.auditDate | dateFormat }}
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(expenseModeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>
            <el-table-column label="操作" width="70" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click="handleDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="公共费用报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/公共费用报销.png" alt="公共费用报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit v-if="editShow" :isShow.sync="editShow" :getTitle="sonTitle" :options="form"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../edit/CostEdit.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    NumberRangeInput: () => import('@/components/number-range-input.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    SubjectCascader: () => import('@/components/subject-cascader.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0, // 表格 -- 数据 总数量
        expenseNumber: null, //单据编号
        deptId: null, //部门id
        userName: null, //姓名
        companyType: null, //所属单位
        actStatus: null, //当前报销进度
        startDate: null, //查询开始时间
        endDate: null, //查询结束时间
        startCost: null,
        endCost: null,
        typeOne: null,
        typeTwo: null,
      },
      subjectList: [],
      numberRange: [],
      pageSize: 0,
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      companyTypeList: [], //公司所属单位列表
      createdDate: [], //查询内容申请时间
      drawer: false, //流程图显示隐藏
      deptList: [], // 部门 列表
      dictData: {
        status: {},
      },
      expenseModeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
      exportingLoading: false,
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  async created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    // await this.getStatus(); /** 加载 "报销状态" 字典数据 **/
    await this.getCompanyList() /** 加载 "所属单位" 列表数据 **/
    await this.getDeptList() /** 加载 "部门" 列表数据 **/
    await this.getTableData()
    this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
      this.expenseModeList = res.data
    })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.type': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.getTableData()
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    exportingFn() {
      this.exportingLoading = true
      this.$api.publicSpending
        .publicSpendingExpenseListExport(this.parameter)
        .then(res => {
          const fileName = '公共费用报销明细.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.exportingLoading = false
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          this.exportingLoading = false
          console.log(e)
        })
    },

    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "报销状态" 字典数据 **/
    getStatus() {
      /**
       * 更换字典参数
       *
       */
      this.$api.dict.listSysDictData('PROJECT_COST_STATUS', true).then(res => {
        this.dictData.status = res.data
      })
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },

    /** 加载数据 **/
    getTableData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      if (this.numberRange && this.numberRange.length > 0) {
        this.parameter.startCost = this.numberRange[0]
        this.parameter.endCost = this.numberRange[1]
      } else {
        this.parameter.startCost = null
        this.parameter.endCost = null
      }
      if (this.subjectList && this.subjectList.length > 0) {
        this.parameter.typeOne = this.subjectList[0]
        this.parameter.typeTwo = this.subjectList[1] ? this.subjectList[1] : null
      } else {
        this.parameter.typeOne = null
        this.parameter.typeTwo = null
      }

      this.loading = true
      /*
       ****todo
       ****切换公共费用明细列表接口
       ****
       */
      this.$api.publicSpending
        .publicSpendingExpenseAllList(this.parameter)
        .then(res => {
          this.loading = false
          // this.parameter.total = res.data.total;
          // res.data.records.forEach((item) => {
          //   // 根据companyType查公司所属单位
          //   const findCompanyType = this.companyTypeList.find(
          //     (companyTypeItem) => companyTypeItem.dictVal == item.companyType
          //   );
          //   if (findCompanyType) {
          //     item.companyTypeName = findCompanyType.dictName;
          //   }
          // });
          // this.tableData = res.data.records;

          this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
          this.tableData.forEach(item => {
            item.sumCost = Number(item.sumCost ? item.sumCost : 0)
          })
          this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.expenseNumber = null
      this.parameter.companyType = null
      this.parameter.actStatus = null
      this.parameter.deptId = null
      this.parameter.userName = null
      this.parameter.startCost = null
      this.parameter.endCost = null
      this.parameter.typeOne = null
      this.parameter.typeTwo = null
      this.subjectList = []
      this.parameter.pageNow = 1
      this.createdDate = []
      this.numberRange = []
      this.getTableData()
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetail(item) {
      this.sonTitle = '查看报销单详情'
      this.form = item
      this.form.detailType = 0
      this.form.hideRejectButton = true
      this.form.list_s = 4
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    handlerPage(page) {
      this.parameter.pageNow = page
      this.getTableData()
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.form = {}
      this.getTableData()
      this.editShow = false
    },

    dataUpdate() {
      this.$forceUpdate()
    },
  },

  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.oneline {
  display: flex;
  justify-content: space-between;
}
.search_center {
  display: flex;
  align-items: center;
}

.row1 {
  display: flex;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
